import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options_data = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Vremena najveće posjećenosti',
        },
    },
};

const data = {
    labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
    datasets: [
        {
            label: "Ukupan broj posjeta",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: "rgba(34, 139, 230, 0.75)",
            borderColor: "rgba(24, 130, 220, 1)",
            borderWidth: 0
        }
    ]
};

export default function FrequencyChart({input}: any) { // Ne zaboravi {} wrapper
    console.log("Making array with",input.frequency_array)
    data.datasets[0].data = input.frequency_array
    return <Bar options={options_data} data={data} />;
}