import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Image,
  Divider,
} from '@mantine/core';

import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useSignup } from '../hooks/useSignup';
import NonDiacriticTextInput from '../components/NonDiacriticTextInput';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage:
      'url("img/login_splash5.jpg")',
  },

  form: {
    borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    width: "450px",
    paddingTop: 80,
    position: "fixed",
    top: "15vh",
    height: "100%",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    display: 'block',
    width: "450px",
    top: 0,
    position: "fixed",
    height: "15vh"
  },
}));

export default function Signup() {
  const { classes } = useStyles();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("");
  const { signup, isLoading } = useSignup()
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (await signup(email, password, name) == true) {
      navigate("/dashboard");
    }
  }
  if (localStorage.getItem("user")) {
    return <Navigate replace to='/dashboard' />
  } else {
    return (
      <div className={classes.wrapper}>
        <div className={classes.logo} style={{ backgroundColor: "black" }}>
          <Image
            src="img/logo_long.png"
            style={{ width: "50%", paddingTop: "10%", paddingBottom: "10%", margin: "auto" }}
          />
        </div>
        <Paper className={classes.form} radius={0} p={30}>
          <Title order={2} className={classes.title} align="left" mt="md" mb={20}>
            Dobrodošli!
          </Title>
          <form onSubmit={handleSubmit}>
            <TextInput
              label="Email"
              placeholder="Vaša E-mail adresa"
              size="md"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />

            <PasswordInput
              label="Lozinka"
              placeholder="Vaša lozinka"
              mt="md"
              size="md"
              onChange={(event) => setPassword(event.target.value)}
              value={password}
            />

            <NonDiacriticTextInput 
              label="Naziv teretane"
              mt="md"
              size="md"
              onChange={(event: any) => setName(event.target.value)}
              value={name}
            />

            {/*<Checkbox label="Zapamti me" mt="xl" size="md" />*/}
            <Button
              fullWidth mt="xl"
              size="md"
              disabled={isLoading}
              onClick={handleSubmit}
              type="submit"
            >
              Registracija
            </Button>
          </form>
          <Text align="center" mt="md">
            Imaš postojeći račun?{' '}
            <Anchor<'a'> href="/login" weight={700}>
              Prijava
            </Anchor>
          </Text>

        </Paper>
      </div>
    );
  }
}