export default function acronymFromName(name: string) : string {
    const trimmedName = name.trim();
    const words = trimmedName.split(/\s+/);
        
    if (words.length === 1) {
        return words[0].substring(0, 2).toUpperCase();
    }
        
    const acronym = words.map(word => word[0].toUpperCase()).join('');
    return acronym;
}