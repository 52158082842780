import { Paper, Title, Text, UnstyledButton, Badge, Avatar, Group, Stack, Collapse, Button, Divider, Highlight, Table } from "@mantine/core";
import { IconCalendar, IconChevronDown, IconChevronUp, IconSettings, IconTrash, IconUsers } from "@tabler/icons";
import { openModal } from '@mantine/modals';
import { useDeleteGroup } from "../../hooks/useMemberGroups";
import { openConfirmationModal } from "../ConfirmationModal";
import ScheduleTable from "../DateAndTime/ScheduleTable";
import GroupEditModal from "./GroupEditModal";
import { useGetMembers } from "../../hooks/Members/useGetMembers";
import { DateToString } from "../../utils/dateManager";

interface GroupProps {
    _id: string,
    name: string,
    leader: string,
    color: string,
    memberCount: number,
    openedGroupId: string | null,
    schedule: any,
    setOpenedGroupId: (id: string | null) => void,
    refresh: () => void;
    openEditModal: (id: string) => void
}

function MemberGroup({ _id, name, leader, color, memberCount, openedGroupId, schedule, setOpenedGroupId, refresh, openEditModal }: GroupProps) {
    const { remove } = useDeleteGroup()
    const { getMembersByGroup } = useGetMembers()

    const isOpened = openedGroupId === _id;

    const handleToggle = () => {
        setOpenedGroupId(isOpened ? null : _id);
    };

    const handleDelete = () => {
        openConfirmationModal({ onContinue: () => { remove(_id, name); refresh() }, warning: "Jeste li da želite obrisati ovu grupu?" });
    }

    const handleShowGroupMembers = async () => {
        let members = await getMembersByGroup(_id);  // Fetch members by group
    
        openModal({
            title: `${name}:`,
            size: "70vw",
            padding: "md",
            children: (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>Ime člana</th>
                                <th>Datum rođenja</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member: any) => (
                                <tr key={member._id}>
                                    <td>{member.name}</td>
                                    <td>{DateToString(new Date(member.date_birth))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ),
        });
    };

    const handleShowTimetable = () => {
        openModal({
            title: `Rasporeda za: ${name}`,
            size: "70vw",
            padding: "md",
            children: (
                <>
                    <ScheduleTable schedule={schedule} />
                </>
            ),
        });
    }

    const avatar = (
        <Avatar size={24} mr={5} src="image-link"><IconUsers size={24}></IconUsers></Avatar>
    );
    const chevronIcon = isOpened ? <IconChevronUp size={24} /> : <IconChevronDown size={24} />;

    return (
        <>
            <UnstyledButton onClick={handleToggle}>
                <Paper shadow="xs" radius="lg" p="md" withBorder>
                    <Group position="apart" align="center">
                        <Group spacing="md">
                            <Divider size="md" orientation="vertical" color={color} />
                            <Stack align="flex-start" spacing="xs">
                                <Group>
                                    <Title order={4}>{name}</Title>
                                    {/* <Badge sx={{ paddingLeft: 0 }} size="lg" radius="xl" color={color} leftSection={avatar}>
                                        {memberCount}
                                    </Badge> */}
                                </Group>
                                <Text size="md" color="dimmed">{leader}</Text>
                            </Stack>
                        </Group>
                        <div>
                            {chevronIcon}
                        </div>
                    </Group>
                </Paper>

                <Collapse in={isOpened}>
                    <Group position="apart" align="center">
                        <Group spacing="md">
                            <Button mt="xs" ml="lg" mb="xl" radius="lg" leftIcon={<IconUsers size={24} />} onClick={handleShowGroupMembers}>Pregled članova</Button>
                            <Button onClick={() => openEditModal(_id)} mt="xs" ml="lg" mb="xl" radius="lg" leftIcon={<IconSettings size={24} />}>Uredi grupu</Button>
                            <Button mt="xs" ml="lg" mb="xl" radius="lg" onClick={handleShowTimetable} leftIcon={<IconCalendar size={24} />}>Pregled tjednog rasporeda</Button>
                        </Group>
                        <Button mt="xs" ml="lg" mb="xl" radius="lg" color="red" onClick={handleDelete} leftIcon={<IconTrash size={24} />}>Obriši grupu</Button>
                    </Group>
                </Collapse>
            </UnstyledButton>
        </>
    );
}

export default MemberGroup;