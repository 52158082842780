function convertToBase64(file, cb) {
    var reader = new FileReader();
    reader.onload = function (e) {
        cb(null, e.target.result)
    };
    reader.onerror = function (e) {
        cb(e);
    };
    reader.readAsDataURL(file);
}

function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
    return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            const MAX_WIDTH = maxWidth
            const MAX_HEIGHT = maxHeight
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL('image/jpeg', 0.7))
        }
    })
}

function convertDataURLToFile(dataURL, filename) {
 
    var arr = dataURL.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

export function resizeFileTo256JPEG(file) {
    return new Promise((resolve, reject) => {
        convertToBase64(file, function (err, base64Str) {
            if (err) {
                reject(err)
                return;
            }

            resizeImage(base64Str, 256, 256).then((dataURL) => {
                resolve(convertDataURLToFile(dataURL, "image"))
            });
        })
    })
}