import { Box, Button, Group, Modal, TextInput, Text, ColorInput, ScrollArea, SegmentedControl, Accordion, Divider } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCirclesRelation, IconClock, IconSquareRoundedPlus } from "@tabler/icons";
import { useState } from "react";
import { useCreateGroup } from "../../hooks/useMemberGroups";
import WeeklyTimePicker, { cleanSchedule, Schedule } from "../DateAndTime/WeeklyTimePicker";

interface FormValues {
	name: string;
	color: string;
	leader: string;
	schedule: Schedule;
}

function CreateGroupModal({ callback }: any) {
	const [opened, setOpened] = useState(false);
	const [color, setColor] = useState("");
	const { create } = useCreateGroup();

	const [schedule, setSchedule] = useState<Schedule>({
		PON: [],
		UTO: [],
		SRI: [],
		CET: [],
		PET: [],
		SUB: [],
		NED: [],
	});

	const groupForm = useForm<FormValues>({
		initialValues: {
			color: "",
			name: "",
			leader: "",
			schedule: schedule
		}
	});

	// Handle form submit
	const handleSubmit = async (values: any) => {
		if (values.leader === "") {
			groupForm.setFieldValue("leader", "Nema");
		}	

		// Potvrdi da nema stvorenih, ali neispunjenih vremena
		let cleanedSchedule = cleanSchedule(schedule, true);

		if ((await create(values.color, values.name, values.leader, cleanedSchedule)) === true) {
			groupForm.reset();
			setOpened(false);
			callback();
		}
	};

	return (
		<>
			<Button onClick={() => setOpened(true)} mt="xs" ml="lg" mb="xl" radius="xl" color="green" leftIcon={<IconCirclesRelation/>}>
				Izrada grupe
			</Button>

			<Modal opened={opened} onClose={() => setOpened(false)} title="Izrada grupe">
				<Box sx={{ maxWidth: 600 }} mx="auto">
					<form onSubmit={groupForm.onSubmit((values) => handleSubmit(values))}>
						<ColorInput
							placeholder="Odaberite boju grupe"
							label="Boja grupe"
							format="hex"
							withAsterisk
							withPicker={true}
							mb="xs"
							swatches={['#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
							onChange={(color) => {
								setColor(color);
								groupForm.setFieldValue("color", color);
							}}
						/>

						<TextInput withAsterisk label="Naziv" placeholder="Naziv grupe" mb="xs"
							{...groupForm.getInputProps('name')}
						/>

						<TextInput label="Voditelj" placeholder={`Voditelj / trener grupe`}
							{...groupForm.getInputProps('leader')}
						/>
						<Text c="dimmed" size="xs">Ostaviti prazno ukoliko nema trenera za ovu grupu.</Text>

						<Divider mt="lg" mb="lg" />

						<Accordion defaultValue="">
							<Accordion.Item value="customization">
								<Accordion.Control icon={<IconClock size={20} />}>
									<div>
										<Text>Dodavanje tjednog rasporeda</Text>
										<Text size="xs" color="dimmed" >
											Ostaviti prazno ukoliko se ne odnosi na ovu vrstu grupe.
										</Text>
									</div>
								</Accordion.Control>
								<Accordion.Panel>
									<WeeklyTimePicker onScheduleChange={setSchedule} verticalDisplay={false}/>
								</Accordion.Panel>
							</Accordion.Item>
						</Accordion>

						<Group position="right" mt="md">
							<Button type="submit" variant="filled" mt="lg" style={{ backgroundColor: color }}>
								Izradi grupu
							</Button>
						</Group>
					</form>
				</Box>
			</Modal>
		</>
	);
}

export default CreateGroupModal;
