import { Button, Avatar, Text, Box, Menu, Modal, Flex, Group, Stack } from '@mantine/core';
import { IconUsers, IconDots, IconLogout } from '@tabler/icons';
import { openConfirmationModal } from '../ConfirmationModal';  // Adjust the import path
import { useState } from 'react';
import EmployeesListModal from '../EmployeesListModal';
import { useAuthContext } from '../../hooks/useAuthContext';
import acronymFromName from '../../utils/formatName';


interface ActiveUserProps {
	onLogout: () => void;
}

function ActiveUser({ onLogout }: ActiveUserProps) {
	const handleLogout = () => {
		openConfirmationModal({ onContinue: onLogout, warning: "Jeste li sigurni da se želite odjaviti?" });
	};
	const { user } = useAuthContext();

	const [modalOpened, setModalOpened] = useState(false);
	const callback = () => {
		setModalOpened(!modalOpened);
	}

	return (
		<>
			<Modal opened={modalOpened} withCloseButton={false} centered onClose={() => setModalOpened(false)} padding="md" size="55rem">
				<EmployeesListModal callback={callback} />
			</Modal>

			<Menu shadow="md" width={250}>
				<Menu.Target>
					<Button variant="outline" mb="md" size="xl" color={user.employee.color} radius="xs" sx={{ display: "flex", justifyContent: 'flex-start', paddingLeft: "10px", paddingRight: "5px" }}>
						<Group position="left" spacing="xs">
							<Avatar color={user.employee.color} radius="xl" mr="xs">{acronymFromName(user.employee.name)}</Avatar>
							<Stack align="flex-start" spacing={0}>
								<Text size="md" color={user.employee.color}>{user.employee.name}</Text>
								<Text size="xs" color="white">Djelatnik</Text>
							</Stack>
						</Group>
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item onClick={() => { setModalOpened(true) }} icon={<IconUsers size={14} />}>Pregled svih djelatnika</Menu.Item>
					<Menu.Item color="red" onClick={handleLogout} icon={<IconLogout size={14} />}>Odjavi me</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</>
	);
}

export default ActiveUser;