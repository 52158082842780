import { showSuccess, showError } from "../utils/showPopup"
import { getDefaultHeaders } from "./util/getRequestHeaders";

export const useFreezeUser = () => {
    const freezeUser = async (_id) => {
        const user = JSON.parse(localStorage.getItem("user"))
        const response = await fetch("/api/gym/freeze", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ "_id": _id })
        })

        const json = await response.json()

        if (!response.ok) {
            showError(json.error)
        } else if (response.ok) {
            showSuccess(`Korisnik uspješno zamrznut (dana preostalo: ${json.remaining_days}).`)
            return true
        }
    }

    return { freezeUser }
}