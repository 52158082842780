import { showError, showLoading, updateLoading, showSuccess } from '../utils/showPopup'
import { resizeFileTo256JPEG } from "./ImageResize"
import { getDefaultHeaders, getFormHeaders } from "./util/getRequestHeaders";

export const useUpdateUser = () => {
    const updateUser = async (update_data) => {

        const define_membership = (membership_type) => {
            switch (membership_type) {
                case "1":
                    return {
                        type: "1",
                        weekly: 2,
                        remaining: 2,
                        strict_visit: false
                    }
                case "2":
                    return {
                        type: "2",
                        weekly: 3,
                        remaining: 3,
                        strict_visit: false
                    }
                case "3":
                    return {
                        type: "3",
                        weekly: -1,
                        remaining: -1,
                        strict_visit: false
                    }
                case "4":
                    return {
                        type: "4",
                        weekly: -1,
                        remaining: -1,
                        strict_visit: true
                    }
            }
        }

        let { _id, name, email, phone_number, date_birth, date_due, image, membership_type, rfid, groups } = update_data

        const data = new FormData();
        data.append("_id", _id)
        data.append("name", name)
        data.append("email", email)
        data.append("date_birth", date_birth)
        data.append("date_due", date_due)
        data.append("phone_number", phone_number)
        if (image) {
            await resizeFileTo256JPEG(image).then((resizedFile) => {
                data.append("image", resizedFile)
            })
        }
        data.append("membership_type", JSON.stringify(define_membership(membership_type)))
        data.append("rfid", rfid)
        data.append("groups", JSON.stringify(groups))

        showLoading("Primjenjujemo promjene na serveru...", "useUpdateUser")
        const response = await fetch("/api/gym/update", {
            method: "POST",
            headers: getFormHeaders(),
            body: data
        })

        const json = await response.json()

        if (!response.ok) {
            updateLoading("useUpdateUser", json.error, showError)
        } else if (response.ok) {
            updateLoading("useUpdateUser", `Korisnički podatci uspješno su promijenjeni.`, showSuccess)
            return true
        }
    }

    return { updateUser }
}