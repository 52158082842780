import { Table } from '@mantine/core';
import { useEffect, useState } from 'react';

// Define the type for a TimeRange
export type TimeRange = [string, string];

// Define the type for the schedule
export interface Schedule {
  PON: TimeRange[];
  UTO: TimeRange[];
  SRI: TimeRange[];
  CET: TimeRange[];
  PET: TimeRange[];
  SUB: TimeRange[];
  NED: TimeRange[];
}

export function ConvertDatabaseObjectToSchedule(ScheduleObject: any){
  const schedule: Schedule = {
    PON: ScheduleObject.PON,
    UTO: ScheduleObject.UTO,
    SRI: ScheduleObject.SRI,
    CET: ScheduleObject.CET,
    PET: ScheduleObject.PET,
    SUB: ScheduleObject.SUB,
    NED: ScheduleObject.NED
  };
  return schedule
}

// Helper function to format the time
const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
};

interface ScheduleTableProps {
  schedule: Schedule;
}

const ScheduleTable = ({ schedule }: ScheduleTableProps) => {
  // Determine the maximum number of rows (time ranges) across all days
  const maxRows = Math.max(
    schedule.PON.length,
    schedule.UTO.length,
    schedule.SRI.length,
    schedule.CET.length,
    schedule.PET.length,
    schedule.SUB.length,
    schedule.NED.length,
  );

  return (
    <Table>
      <thead>
        <tr>
          <th>PON</th>
          <th>UTO</th>
          <th>SRI</th>
          <th>ČET</th>
          <th>PET</th>
          <th>SUB</th>
          <th>NED</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: maxRows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            <td>{schedule.PON[rowIndex] ? `${formatTime(schedule.PON[rowIndex][0])} - ${formatTime(schedule.PON[rowIndex][1])}` : ''}</td>
            <td>{schedule.UTO[rowIndex] ? `${formatTime(schedule.UTO[rowIndex][0])} - ${formatTime(schedule.UTO[rowIndex][1])}` : ''}</td>
            <td>{schedule.SRI[rowIndex] ? `${formatTime(schedule.SRI[rowIndex][0])} - ${formatTime(schedule.SRI[rowIndex][1])}` : ''}</td>
            <td>{schedule.CET[rowIndex] ? `${formatTime(schedule.CET[rowIndex][0])} - ${formatTime(schedule.CET[rowIndex][1])}` : ''}</td>
            <td>{schedule.PET[rowIndex] ? `${formatTime(schedule.PET[rowIndex][0])} - ${formatTime(schedule.PET[rowIndex][1])}` : ''}</td>
            <td>{schedule.SUB[rowIndex] ? `${formatTime(schedule.SUB[rowIndex][0])} - ${formatTime(schedule.SUB[rowIndex][1])}` : ''}</td>
            <td>{schedule.NED[rowIndex] ? `${formatTime(schedule.NED[rowIndex][0])} - ${formatTime(schedule.NED[rowIndex][1])}` : ''}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ScheduleTable;
