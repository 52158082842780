import { getDefaultHeaders } from "../util/getRequestHeaders";
import { showSuccess, showError, showLoading, updateLoading } from '../../utils/showPopup';

export const useExtendMembership = () => {
    const extendMembershipMonths = async (_id, months) => {
        if (typeof months == "number" && months > 0) {
            showLoading("Produžujem članstvo...", "extendMembershipMonths")
            const response = await fetch("/api/gym/extendmonths", {
                method: "POST",
                headers: getDefaultHeaders(),
                body: JSON.stringify({ _id, months})
            })
            const json = await response.json()
            if (!response.ok) {
                updateLoading("extendMembershipMonths", json.error, showError)
            } else if (response.ok) {
                updateLoading("extendMembershipMonths", `Članstvo uspješno produženo.`, showSuccess)
                return true
            }
        }
    }

    const setMembershipManual = async (_id, new_date) => {
            showLoading("Mijenjam članstvo...", "setMembershipManual")
            const response = await fetch("/api/gym/setmembershipmanual", {
                method: "POST",
                headers: getDefaultHeaders(),
                body: JSON.stringify({ _id, new_date})
            })
            const json = await response.json()
            if (!response.ok) {
                updateLoading("setMembershipManual", json.error, showError)
            } else if (response.ok) {
                updateLoading("setMembershipManual", `Članstvo uspješno promijenjeno.`, showSuccess)
                return true
            }
    }

    return {extendMembershipMonths, setMembershipManual}
}