import { useEffect, useState } from 'react';
import { Modal, Badge, Title, Text, Button, HoverCard, Stack, Center, Group } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { DateToString, DateToStringPrecise } from '../utils/dateManager';
import 'dayjs/locale/hr';

import { useExtendMembership } from '../hooks/Members/useExtendMembership';

interface props {
  visible: boolean
  setManualVisible: any;
  data: any;
  setMainModalVisible: (visible: boolean)=>void;
}

function MembershipSetManuallyModal({ visible, setManualVisible, data, setMainModalVisible }: props) {
  const [newDateDue, setNewDateDue] = useState<Date | null>(null);
  const { setMembershipManual } = useExtendMembership() 

  const handleNewDate = (newDate: Date) => {
    newDate.setHours(23, 59, 59);
    setNewDateDue(newDate);
  }

  const handleSubmit = async () => {
    if (newDateDue !== null) {
      newDateDue.setHours(23, 59, 59)
      await setMembershipManual(data._id, newDateDue)
      setManualVisible(false);
      setMainModalVisible(false);
    }
  }

  useEffect(() => {

  }, [visible, newDateDue])

  return (
    <Modal centered
      opened={visible}
      onClose={() => setManualVisible(false)}
      title="Ručno namještanje članarine"
    >
      <Center>      <Title mb="lg">{data.name}</Title>      </Center>
      <Stack>
        <Badge color="gray" radius="xl" size="lg" mt="xs" variant="outline">
          {`TRENUTNI ROK: ${DateToStringPrecise(new Date(data.date_due))}`}
        </Badge>
        <Center>
          <Calendar value={newDateDue} onChange={handleNewDate} locale="hr" />
        </Center>

        {newDateDue !== null ? (<Badge color={(newDateDue > new Date(data.date_due)) ? "green" : "red"} radius="xl" size="lg" mt="xs" variant="outline">
          {`NOVI ROK: ${DateToStringPrecise(newDateDue)}`}
        </Badge>) : ""}

        <Group grow>
          <HoverCard width={200} shadow="md">
            <HoverCard.Target>
              <Button mt="xl" type="submit" onClick={handleSubmit}>Spremi promjene</Button>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">
                Uvijek provjerite unos još jednom prije potvde.
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>

          <Button mt="xl" type="submit" color="red" onClick={() => { setManualVisible(false) }}>Odustani</Button>
        </Group>

      </Stack>
    </Modal>
  );
}

export default MembershipSetManuallyModal