import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ScrollArea,
  Select,
  Pagination,
  LoadingOverlay,
  Transition,
  ActionIcon,
  Indicator,
  Center,
} from "@mantine/core";
import { useState, useEffect, forwardRef, useRef } from "react";

import CreateUserModal from "../../components/CreateUserModal";
import UserEditModal from "../../components/UserEditModal";
import DueMembersModal from "../../components/DueMembersModal";
import { AuthContextProvider } from "../../context/AuthContext";

import { useAuthContext } from "../../hooks/useAuthContext";
import { IconHourglass } from "@tabler/icons";
import { useGetMembers } from "../../hooks/Members/useGetMembers";

function DateToString(date: Date) {
  return new Intl.DateTimeFormat("hr-HR", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  }).format(date);
}

function ReturnImgSrc(image: any) {
  const base64 = window.btoa(
    new Uint8Array(image.data.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const imgSrc = `data:${image.contentType};base64,${base64}`;
  return imgSrc;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  _id: string;
}

// Dodaj kasnije
// https://ui.mantine.dev/component/table-sort

export default function Članovi() {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [update, setUpdate] = useState(false);
  const [searchableUsers, setSearchableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("donotdisplay");
  const [editVisible, setEditVisible] = useState(false);
  const [activePage, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1)
  const [memberCount, setMemberCount] = useState(0)

  const { getMembersInfo, getMembers } = useGetMembers();

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, _id, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group
          onMouseDown={(e) => {
            setSelectedUser(_id);
            setEditVisible(true);
            e.preventDefault();
          }}
          noWrap
        >
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  useEffect(() => {
    const loadBasicData = async () => {
      let { totalPages, totalUsers } = await getMembersInfo();
      setPageCount(totalPages)
      setMemberCount(totalUsers)
    }

    loadBasicData()
  }, [])

  useEffect(() => {

    const loadMembers = async () => {
      // User fetching
      const { minimalList, paginatedList } = await getMembers(activePage);

      let searchableUsersArray = minimalList.map((user: any) => ({
        label: user.name,
        value: user.name,
        _id: user._id,
        description: DateToString(new Date(Date.parse(user.date_birth)))
      }))
      setSearchableUsers(searchableUsersArray)

      // Creating the components
      setRows(paginatedList.map((item: any) => (
        <tr key={item._id}>
          <td>
            <Group spacing="sm">
              <Avatar src={ReturnImgSrc(item.image)} size={40} radius={40} />
              <div>
                <Text
                  size="sm"
                  weight={500}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setSelectedUser(item._id);
                    setEditVisible(true);
                    e.preventDefault();
                  }}
                >
                  {item.name}
                </Text>
                <Text size="xs" color="dimmed">
                  {item.email}
                </Text>
              </div>
            </Group>
          </td>
          <td>
            {DateToString(new Date(Date.parse(item.date_joined)))}
          </td>
          <td>
            {DateToString(new Date(Date.parse(item.date_lastseen)))}
          </td>
          <td>
            {item.frozen_status
              ? "Neodređeno"
              : DateToString(new Date(Date.parse(item.date_due)))}
          </td>
          <td>
            {item.frozen_status ? (
              <Badge variant="filled" color="cyan" fullWidth>
                Zamrznuto
              </Badge>
            ) : Date.parse(item.date_due) > Date.now() ? (
              <Badge variant="filled" color="green" fullWidth>
                Vrijedi
              </Badge>
            ) : (
              <Badge variant="filled" color="red" fullWidth>
                Istekla
              </Badge>
            )}
          </td>
        </tr>
      )))
    };

    setLoading(true)
    loadMembers().then(() => {
      setLoading(false)
    })
  }, [update, activePage])

  const callback = () => {
    setUpdate(!update);
  };

  return (
    <div>
      <Select
        placeholder="Traži člana po imenu"
        searchable
        clearable
        itemComponent={SelectItem}
        nothingFound="Nema rezultata"
        maxDropdownHeight={280}
        limit={10}
        data={searchableUsers}
      />
      <Center mt="md" mb="md">
        <Pagination page={activePage} onChange={setPage} total={pageCount} />
      </Center>
      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <thead>
            <tr>
              <th>Članovi ({memberCount})</th>
              <th>Član od</th>
              <th>Zadnji put viđen</th>
              <th>Rok članarine</th>
              <th>Status članarine</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <>
        <CreateUserModal callback={callback} />
        <UserEditModal
          visible={editVisible}
          callback={callback}
          setEditVisible={setEditVisible}
          _id={selectedUser}
        />
        <DueMembersModal />
      </>
    </div>
  );
}
