import {
  Avatar,
  Center,
  Group,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";

import { useGetLogs } from "../../hooks/useGetLogs";
import acronymFromName from "../../utils/formatName";

function DateToString(date: Date) {
  return new Intl.DateTimeFormat("hr-HR", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Ensures 24-hour format
  }).format(date);
}

function Logger() {
  const { getLogsInfo, getLogs } = useGetLogs();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [logCount, setLogCount] = useState(0);
  const [activePage, setPage] = useState(1);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const loadBasicData = async () => {
      let { totalPages, totalLogs } = await getLogsInfo();
      setPageCount(totalPages);
      setLogCount(totalLogs);
    };

    loadBasicData();
  }, []);

  useEffect(() => {
    const loadLogs = async () => {
      const { minimalList, paginatedList } = await getLogs(activePage);

      setRows(
        paginatedList.map((item: any) => (
          <tr key={item._id}>
            <td>
              <Group spacing="sm">
                <Avatar size={40} radius={40}>
                  {acronymFromName(item.user)}
                </Avatar>
                <Text size="md">{item.user}</Text>
              </Group>
            </td>
            <td>
              <Text size="sm" color="grey">
                {item.action} {item.data}
              </Text>
            </td>
            <td>
              <Text size="sm" color="grey">
                {DateToString(new Date(Date.parse(item.date)))}
              </Text>
            </td>
          </tr>
        ))
      );
    };

    setLoading(true);
    loadLogs().then(() => {
      setLoading(false);
    });
  }, [update, activePage]);

  const callback = () => {
    setUpdate(!update);
  };

  return (
    <div>
      <Center>
        <Pagination page={activePage} onChange={setPage} total={pageCount} />
      </Center>
      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
          <LoadingOverlay visible={loading} overlayBlur={2} />
          {/* <thead>
            <tr>
              <th>Korisnik</th>
              <th>Action</th>
              <th>Date</th>
            </tr>
          </thead> */}
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </div>
  );
}

export default Logger;
