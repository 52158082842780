import {
  Button,
  Divider,
  Group,
  PasswordInput,
  ScrollArea,
  Title,
  Text,
} from "@mantine/core";
import { IconLock } from "@tabler/icons";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useChangeEmployeePassword } from "../../hooks/useEmployeesHook";
import { useState } from "react";

interface NewPasswordProps {
  callback: () => void;
}

function NewPassword({ callback }: NewPasswordProps) {
  const { user } = useAuthContext();
  const { change } = useChangeEmployeePassword();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordNotSameError, setPasswordNotSameError] = useState("");

  const handlePasswordChange = async () => {
    if (newPassword != confirmNewPassword) {
      setPasswordNotSameError("Lozinke nisu iste!");
      return;
    } else {
      setPasswordNotSameError("");
    }

    if ((await change(user.employee._id, oldPassword, newPassword)) === true) {
      setOldPassword("")
      setNewPassword("")
      setConfirmNewPassword("")
      callback()
      return;
    }
  };

  return (
    <>
      <ScrollArea style={{ height: "50vh" }}>
        <Group>
          <IconLock size="30"></IconLock>
          <Title order={2}>Promjena lozinke</Title>
        </Group>
        <Divider mt="md" mb="lg" />
        <Title order={3} mb="md">
          Korisnik: {user.employee.name}
        </Title>
        <PasswordInput
          withAsterisk
          label="Trenutna lozinka"
          placeholder={`Trenutna lozinka za ${user.employee.name}`}
          onChange={(event) => {
            setOldPassword(event.target.value);
          }}
        />
        <Text c="dimmed" size="xs">
          U slučaju zaboravljene lozinke, javite nam se na info@gymchain.fit
        </Text>
        <PasswordInput
          withAsterisk
          mt="md"
          label="Nova lozinka"
          placeholder="Nova lozinka"
          onChange={(event) => {
            setNewPassword(event.target.value);
          }}
          error={passwordNotSameError}
        />
        <PasswordInput
          withAsterisk
          label="Potvrdite lozinku"
          placeholder="Potvrdite lozinku"
          onChange={(event) => {
            setConfirmNewPassword(event.target.value);
          }}
          error={passwordNotSameError}
        />
        <Button
          mt="md"
          type="submit"
          variant="filled"
          color={user.employee.color}
          onClick={handlePasswordChange}
        >
          Promijeni lozinku
        </Button>
      </ScrollArea>
    </>
  );
}

export default NewPassword;
