import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { AuthContextProvider } from "./context/AuthContext"
import { NotificationsProvider } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode> // OVO UZROKUJE DUPLO PRINTANJE SVEGA OBRISI KAD TI NE TREBA ALI OSTAVI
  <MantineProvider
    theme={{ fontFamily: "Inter, sans-serif", headings: {fontFamily:"Inter, sans-serif"}  }}
    withGlobalStyles
  >
    <ModalsProvider>
    <NotificationsProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </NotificationsProvider>
    </ModalsProvider>
  </MantineProvider>
  //</React.StrictMode>
);

