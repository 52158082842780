import { showNotification, updateNotification } from '@mantine/notifications'
import { IconCheck, IconExclamationCircle, IconX } from '@tabler/icons'

export function showHint(text: string, persist?: boolean){
    showNotification({
        title: 'Hej!',
        message: text,
        icon: <IconExclamationCircle />,
        color: "blue",
        disallowClose: false,
        autoClose: persist ? false : 5000
    })
}

export function showLoading(text: string, id: string){
    showNotification({
        title: 'Pričekajte...',
        message: text,
        id: id,
        loading: true,
        disallowClose: true,
        autoClose: false,
    })
}

export function updateLoading(id: string, text: string, directTo: Function){
    updateNotification({message: "Učitano.", id: id, autoClose: 1})
    directTo(text)
}

export function showSuccess(text: string){
    showNotification({
        title: 'Uspjeh!',
        message: text,
        icon: <IconCheck />,
        color: "teal",
        disallowClose: false,
    })
}

export function showError(text: string){
    showNotification({
        title: 'Došlo je do problema!',
        message: text,
        icon: <IconX />,
        color: "red",
        disallowClose: true,
    })
}