import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Dialog,
  Indicator,
  Modal,
  ScrollArea,
  Text,
} from "@mantine/core";
import React, { useRef, useEffect, useState } from "react";
import { FetchSoonDueMembers } from "../hooks/MembersHook";
import { IconHourglass } from "@tabler/icons";

function DueMembersModal() {
  const [dueOpened, setDueOpened] = useState(false);
  const [dueDays, setDueDays] = useState(7);
  const [dueMembers, setDueMembers] = useState<any[]>([]);
  const [dueMembersCount, setDueMembersCount] = useState(0);

  useEffect(() => {
    console.log("Fetching due members");
    FetchSoonDueMembers(dueDays).then((data) => {
      if (data) {
        var users = data.map(
          (user: { name: string; image: Object; date_due: string }) => ({
            label: user.name,
            value: user.name,
            date_due: user.date_due,
            image: ReturnImgSrc(user.image),
          })
        );
        setDueMembers(users);
        setDueMembersCount(users.length);
      } else {
        setDueMembers([]);
        setDueMembersCount(0);
      }
    });
  }, []);

  useEffect(() => {
    FetchSoonDueMembers(dueDays).then((data) => {
      if (data) {
        var users = data.map(
          (user: { name: string; image: Object; date_due: string }) => ({
            label: user.name,
            value: user.name,
            date_due: user.date_due,
            image: ReturnImgSrc(user.image),
          })
        );
        setDueMembers(users);
        setDueMembersCount(users.length);
      } else {
        setDueMembers([]);
        setDueMembersCount(0);
      }
    });
  }, [dueOpened, dueDays]);

  function ReturnImgSrc(image: any) {
    const base64 = window.btoa(
      new Uint8Array(image.data.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    const imgSrc = `data:${image.contentType};base64,${base64}`;
    return imgSrc;
  }
  function daysCounter(date_due: any) {
    const dueDate = new Date(date_due).getTime();
    const now = new Date().getTime();
    const difference = dueDate - now;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    return days;
  }
  function color(days: number) {
    if (days <= 3) {
      return "red";
    } else if (days <= 7) {
      return "orange";
    } else {
      return "gray";
    }
  }
  return (
    <>
      <div style={{ position: "fixed", bottom: "5%" }}>
        <div>
          <Indicator
            size={25}
            label={dueMembersCount}
            showZero={false}
            dot={false}
            withBorder
          >
            <ActionIcon
              variant="filled"
              color="dark"
              size={40}
              onClick={() => setDueOpened(!dueOpened)}
            >
              <IconHourglass size={25} />
            </ActionIcon>
          </Indicator>
        </div>
      </div>
      <Dialog
        withBorder
        withCloseButton
        opened={dueOpened}
        onClose={() => {
          setDueOpened(!dueOpened);
          setDueMembers([]);
        }}
        size="lg"
        radius="lg"
        position={{ bottom: "5%", left: "20%" }}
        shadow="xl"
        p={25}
        transition="slide-up"
        transitionDuration={300}
        transitionTimingFunction="ease"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {dueDays === 7 && (
            <>
              <Button
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                  borderRight: "1px solid #ccc",
                  width: "50%",
                }}
                variant="outline"
                onClick={() => setDueDays(7)}
              >
                u 7 dana
              </Button>
              <Button
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 15,
                  borderBottomRightRadius: 15,
                  width: "50%",
                }}
                onClick={() => setDueDays(14)}
              >
                u 14 dana
              </Button>
            </>
          )}
          {dueDays === 14 && (
            <>
              <Button
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                  borderRight: "1px solid #ccc",
                  width: "50%",
                }}
                onClick={() => setDueDays(7)}
              >
                u 7 dana
              </Button>
              <Button
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 15,
                  borderBottomRightRadius: 15,
                  width: "50%",
                }}
                variant="outline"
                onClick={() => setDueDays(14)}
              >
                u 14 dana
              </Button>
            </>
          )}
        </div>
        <ScrollArea.Autosize
          maxHeight={500}
          sx={{ maxWidth: 500 }}
          mx="auto"
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          {dueMembers.map((member) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Avatar src={member.image} size={40} radius={40} />
                  <Text
                    size="lg"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {member.label}
                  </Text>
                </div>
              </div>
              <Badge
                size="md"
                variant="outline"
                color={color(daysCounter(member.date_due))}
              >
                {daysCounter(member.date_due)} dana do isteka
              </Badge>
            </div>
          ))}
        </ScrollArea.Autosize>
        {dueMembersCount == 0 && (
          <Text
            fw={500}
            c="dimmed"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              marginTop: "20px",
            }}
          >
            Nema clanova
          </Text>
        )}
      </Dialog>
    </>
  );
}

export default DueMembersModal;
