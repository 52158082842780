import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';

// Define the interface for props
interface ConfirmationModalProps {
    onContinue: () => void;
    warning: string;
}

// Adjust the function to use the interface
export function openConfirmationModal({onContinue, warning }: ConfirmationModalProps) {
  openConfirmModal({
    title: 'Potrebna potvrda',
    centered: true,
    children: (
      <Text size="sm">
        {warning}
      </Text>
    ),
    labels: { confirm: 'Potvrdi', cancel: 'Odustani' },
    onConfirm: () => onContinue(),
  });
}
