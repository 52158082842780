import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import { showError, showSuccess, showHint } from '../utils/showPopup'

export const useSignup = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { setContextState } = useAuthContext()

    const signup = async (email, password, name) => {
        setIsLoading(true)

        const response = await fetch("/api/user/signup", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, password, name })
        })

        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            showError(json.error)
        } else if (response.ok) {
            // spremi kao cookie
            localStorage.setItem("user", JSON.stringify(json))
            localStorage.setItem("api_key", json.api_key)

            // auth context
            setContextState({ type: "LOGIN", payload: json })

            setIsLoading(false)
            showSuccess("Uspješno ste se registrirali!")
            showHint("Predlažemo da popunite osnovne podatke prije nego što nastavite!", true)
            return true
        }
    }

    return { signup, isLoading }
}
