export const getDefaultHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    let headers = {
        "Authorization": `Bearer ${user.token}`,
        "Employee": JSON.stringify(user.employee),
        "Content-Type": "application/json"
    }
    return headers
}

export const getFormHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    let headers = {
        "Authorization": `Bearer ${user.token}`,
        "Employee": JSON.stringify(user.employee),
    }
    return headers
}