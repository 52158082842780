import { useEffect, useState } from 'react';
import { Modal, Badge, Title, Text, Avatar, Timeline, NumberInput, Button, HoverCard, Group } from '@mantine/core';
import { DateToString, DateToStringPrecise, GetDateInMonths } from '../utils/dateManager';
import { IconAlarm, IconExclamationCircle, IconRotateClockwise2 } from '@tabler/icons';

import { useExtendMembership } from '../hooks/Members/useExtendMembership';

interface props {
  visible: boolean
  setExtensionVisible: any;
  data: any;
  duesPaid: boolean;
  setMainModalVisible: (visible: boolean)=>void;
}

function MembershipExtensionModal({ visible, setExtensionVisible, data, duesPaid, setMainModalVisible }: props) {
  const [months, setMonths] = useState(1)
  const [date_due, setDateDue] = useState(new Date())
  const { extendMembershipMonths } = useExtendMembership()

  const handleSubmit = async () => {
    await extendMembershipMonths(data._id, months);
    setExtensionVisible(false)
    setMainModalVisible(false)
  }

  const handleMonthChange = (months: number) => {
    setMonths(months)
    let membership_due: Date
    if (duesPaid) {
      membership_due = GetDateInMonths(months, new Date(data.date_due))
    } else {
      membership_due = GetDateInMonths(months)
    }
    setDateDue(membership_due)
  };

  useEffect(() => {
    handleMonthChange(months)
  }, [visible])

  return (
    <Modal centered
      opened={visible}
      onClose={() => setExtensionVisible(false)}
      title="Produživanje članarine"
    >
      <Title mb="lg">{data.name}</Title>

      <NumberInput
        withAsterisk
        min={1}
        max={12}
        defaultValue={1}
        mt="xs"
        label="Produži članarinu za broj mjeseci"
        placeholder="1"
        mb="lg"
        onChange={handleMonthChange}
      // {...userForm.getInputProps('membership_months')}
      />

      <Timeline active={1} bulletSize={35} lineWidth={4}>
        <Timeline.Item bullet={<IconAlarm size={23} />} title="Trenutno članstvo">
          <Badge color={duesPaid ? "green" : "red"} radius="xl" size="lg" mt="xs" variant="outline">
            {DateToStringPrecise(new Date(data.date_due))}
          </Badge>
          <Text color="dimmed" mt="xs" size="sm">{duesPaid ? "Članarina nije istekla." : "Članarina je istekla."}</Text>
        </Timeline.Item>

        <Timeline.Item bullet={<IconRotateClockwise2 size={23} />} title="Novo članstvo">
          <Text color="dimmed" mt="xs" size="sm">Vrijedi do</Text>
          <Badge color="green" radius="xl" size="lg" mt="xs" variant="outline">
            {DateToStringPrecise(date_due)}
          </Badge>
        </Timeline.Item>
      </Timeline>

      <Group>
      <HoverCard width={280} shadow="md">
        <HoverCard.Target>
        <Button mt="xl" type="submit" onClick={handleSubmit}>Spremi promjene i zatvori</Button>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Text size="sm">
            Provjerite unos još jednom prije potvde, ovaj postupak je nepovratan.
          </Text>
        </HoverCard.Dropdown>
      </HoverCard>
      <Button mt="xl" type="submit" color="red" onClick={() => { setExtensionVisible(false) }}>Odustani</Button>
      </Group>

    </Modal>
  );
}

export default MembershipExtensionModal