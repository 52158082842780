import { showSuccess, showError } from "../utils/showPopup"
import { getDefaultHeaders } from "./util/getRequestHeaders";

export const useDeleteUser = () => {
    const deleteUser = async (_id) => {
        console.log(_id)
        const response = await fetch("/api/gym/delete", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ _id })
        })

        const json = await response.json()

        if (!response.ok) {
            showError(json.error)
        } else if (response.ok) {
            showSuccess(`Korisnik uspješno obrisan.`)
            return true
        }
    }

    return { deleteUser }
}