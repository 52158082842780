import { useAuthContext } from "./useAuthContext"
import { showSuccess } from "../utils/showPopup"

export const useLogout = () => {
    const { setContextState } = useAuthContext()

    const logout = () => {

        localStorage.removeItem('user')
        localStorage.removeItem('employee')
        localStorage.removeItem('api_key')

        setContextState({ type: 'LOGOUT' })
        showSuccess("Uspješno ste odjavljeni!")
    }
    return { logout }
}