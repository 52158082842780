import { createContext, useReducer, useEffect } from 'react'

import jwtDecode from "jwt-decode"
export const AuthContext = createContext()

function hasTokenExpired(authToken) {
    try {
        const currentTime = Date.now() / 1000;
        const decodedToken = jwtDecode(authToken);
        return decodedToken.exp < currentTime
    } catch (error) {
        return true
    }
}

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            console.log("Logging in as", action.payload)
            return { user: action.payload }
        case 'LOGOUT':
            console.log("Logging user out!")
            return { user: null }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, setContextState] = useReducer(authReducer, {
        user: null
    })

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user ) {
            if (hasTokenExpired(user.token)) {
                setContextState({ type: 'LOGOUT', payload: user })
            } else {
                setContextState({ type: 'LOGIN', payload: user })
            }
        }
    }, [])

    console.log('AuthContext state:', state)

    return (
        <AuthContext.Provider value={{ ...state, setContextState }}>
            {children}
        </AuthContext.Provider>
    )

}