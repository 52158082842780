import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import RFIDDisplay from "./pages/RFIDDisplay";
import NotFound from "./pages/404NotFound";
import Test from "./pages/Test";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

import { useAuthContext } from "./hooks/useAuthContext";
export default function App() {
  function StrictNoAuth({ children }: { children: JSX.Element }) {
    const { user } = useAuthContext();
    let location = useLocation();

    if (!user) {
      return children;
    } else {
      return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }
  }

  function RequireAuth({ children }: { children: JSX.Element }) {
    const { user } = useAuthContext();
    let location = useLocation();

    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Navigate to="/dashboard" />
              </RequireAuth>
            }
          />
          <Route
            path="/login"
            element={
              <StrictNoAuth>
                <Login />
              </StrictNoAuth>
            }
          />
          <Route
            path="/signup"
            element={
              <StrictNoAuth>
                <Signup />
              </StrictNoAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/auth/:rfid_id" element={<RFIDDisplay />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}
