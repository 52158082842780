import { showError, showLoading, updateLoading, showSuccess } from '../utils/showPopup'
import { resizeFileTo256JPEG } from "./ImageResize"
import { getFormHeaders } from "./util/getRequestHeaders";

export const useRegister = () => {
    const register = async (full_name, email, date_birth, phone_number, image, membership_type, membership_months, groups) => {
        const define_membership = (membership_type) => {
            switch (membership_type) {
                case "1":
                    return {
                        type: "1",
                        weekly: 2,
                        remaining: 2,
                        strict_visit: false
                    }
                case "2":
                    return {
                        type: "2",
                        weekly: 3,
                        remaining: 3,
                        strict_visit: false
                    }
                case "3":
                    return {
                        type: "3",
                        weekly: -1,
                        remaining: -1,
                        strict_visit: false
                    }
                case "4":
                    return {
                        type: "4",
                        weekly: -1,
                        remaining: -1,
                        strict_visit: true
                    }
            }
        }
        const data = new FormData();
        data.append("name", full_name)
        data.append("email", email)
        data.append("date_birth", date_birth)
        data.append("phone_number", phone_number)
        data.append("membership_type", JSON.stringify(define_membership(membership_type)))
        data.append("membership_months", membership_months)
        data.append("groups", JSON.stringify(groups))
        showLoading("Kreiramo korisnika...", "useRegister")

        if (image == "") {
            updateLoading("useRegister", "Molimo vas dodajte sliku!", showError)
            return
        }

        await resizeFileTo256JPEG(image).then((resizedFile) => {
            data.append("image", resizedFile)
            console.log("Resized from", image.size, "to", resizedFile.size)
        })

        const response = await fetch("/api/gym/register", {
            method: "POST",
            headers: getFormHeaders(),
            body: data
        })

        const json = await response.json()


        if (!response.ok) {
            updateLoading("useRegister", json.error, showError)
        } else if (response.ok) {
            updateLoading("useRegister", `Korisnik ${full_name} uspješno kreiran.`, showSuccess)
            return true
        }
    }

    return { register }
}