import { showError, showLoading, updateLoading, showSuccess } from '../utils/showPopup'
import { getDefaultHeaders, getFormHeaders } from "./util/getRequestHeaders";
export const useCreateEmployee = () => {
    const create = async (name, password, color) => {
        const data = new FormData();
        data.append("name", name)
        data.append("password", password)
        data.append("color", color)
        showLoading("Kreiramo djelatnika...", "useCreateEmployee")
        const response = await fetch("/api/employee/create", {
            method: "POST",
            headers: getFormHeaders(),
            body: data
        })
        const json = await response.json()
        if (!response.ok) {
            updateLoading("useCreateEmployee", json.error, showError)
        } else if (response.ok) {
            updateLoading("useCreateEmployee", `Djelatnik ${name} uspješno kreiran.`, showSuccess)
            return true
        }
    }
    return { create }
}

export const useGetEmployees = () => {
    const get = async () => {
        // showLoading("Dohvaćamo djelatnike...", "useGetEmployees");
        const response = await fetch("/api/employee/all", {
            method: "GET",
            headers: getDefaultHeaders(),
        })
        const json = await response.json()
        if (!response.ok) {
            // updateLoading("useGetEmployees", json.error, showError)
            showError(json.error)
        } else if (response.ok) {
            // updateLoading("useGetEmployees", `Djelatnici uspješno dohvaćeni.`, showSuccess)
            return json.employees
        }
    }
    return { get }
}

export const useDeleteEmployee = () => {
    const remove = async (_id) => {
        const response = await fetch("/api/employee/delete", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ _id })
        })
        const json = await response.json()
        if (!response.ok) {
            showError(json.error)
        } else if (response.ok) {
            showSuccess(`Djelatnik uspješno izbrisan.`)
            return true
        }
    }
    return { remove }
}

export const useChangeEmployeePassword = () => {
    const change = async (_id, oldPassword, newPassword) => {
        const response = await fetch("/api/employee/changepassword", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ _id, oldPassword, newPassword })
        })
        const json = await response.json()
        if (!response.ok) {
            showError(json.error)
        } else if (response.ok) {
            showSuccess(`Lozinka za djelatnika uspješno promijenjena.`)
            return true
        }
    }
    return { change }
}