import { showSuccess, showError, showLoading, updateLoading } from '../../utils/showPopup';
import { getLastUpdate } from '../util/getLastUpdate';
import { getDefaultHeaders, getFormHeaders } from '../util/getRequestHeaders';

export const useInfo = () => {
    const setInfo = async (image, name, address, description, schedule) => {
        const data = new FormData();
        data.append("image", image)
        data.append("name", name)
        data.append("address", address)
        data.append("description", description)
        data.append("schedule", JSON.stringify(schedule))

        showLoading("Spremamo promjene na server...", "setInfo")

        const response = await fetch("/api/gym/setinfo", {
            method: "POST",
            headers: getFormHeaders(),
            body: data
        })
        const json = await response.json()

        if (!response.ok) {
            updateLoading("setInfo", json.error, showError)
        } else if (response.ok) {
            updateLoading("setInfo", json.message, showSuccess)
            return true
        }
    }

    const getInfo = async () => {
        let cachedInfo = localStorage.getItem('gym_info');
        if (cachedInfo !== "null" && cachedInfo !== null) {
            cachedInfo = JSON.parse(cachedInfo)
            if (cachedInfo._lastUpdate) {
                const lastCacheUpdate = new Date(cachedInfo._lastUpdate)
                const lastDatabaseUpdate = new Date(await getLastUpdate("info"))
                if (lastCacheUpdate >= lastDatabaseUpdate) {
                    return cachedInfo
                }
            }
        }

        const response = await fetch("/api/gym/getinfo", {
            method: "GET",
            headers: getDefaultHeaders(),
            body: JSON.stringify()
        })
        const json = await response.json()
        const info_data = json.info;

        const info = info_data
        if (info != null) {
            delete info._id
            delete info.__v
            info._lastUpdate = new Date();
        }

        if (!response.ok) {
            showError(json.error)
            return {}
        } else if (response.ok) {
            localStorage.setItem("gym_info", JSON.stringify(info))
            return info
        }
    }
    return { setInfo, getInfo }
}