import { useParams } from "react-router-dom";
import {
  createStyles,
  Container,
  Image,
  Title,
  Text,
  Button,
  Group,
  Center,
  Notification,
  LoadingOverlay,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { FetchUserByRFID } from "../hooks/MembersHook";
import { useAuthContext } from "../hooks/useAuthContext";
import { IconCheck, IconX } from "@tabler/icons";
import { useUserEnteredGym } from "../hooks/useUserEnteredGym";

const useStyles = createStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors[theme.primaryColor][3],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,
    color: theme.white,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors[theme.primaryColor][1],
  },
}));

interface Data {
  name: string;
}

function ReturnImgSrc(image: any) {
  const base64 = window.btoa(
    new Uint8Array(image.data.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const imgSrc = `data:${image.contentType};base64,${base64}`;
  return imgSrc;
}

export default function RFIDDisplay() {
  const { classes } = useStyles();
  const { enterGym } = useUserEnteredGym();
  const { rfid_id } = useParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [duesPaid, setDuesPaid] = useState(false);
  const [nastavio, setNastavio] = useState(false);
  const [id, setId] = useState("");

  const [remainingStatus, setRemainingStatus] = useState(false);
  const [strictVisit, setStrictVisit] = useState(false);

  function defineMembership(membership_type: any) {
    const { remaining, strict_visit } = membership_type;
    setRemainingStatus(remaining != 0);
    if (strict_visit) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      if (currentHour >= 14) {
        setStrictVisit(true);
      }
    }
  }

  const handleEnterGym = async () => {
    console.log(rfid_id);
    let success = await enterGym(id);
    console.log(success);
    if (success) {
      setNastavio(true);
    }
  };

  useEffect(() => {
    FetchUserByRFID(rfid_id).then((result) => {
      if (result) {
        let success = result[0];
        if (success) {
          let user = result[1];
          setData(user);
          setName(user.name);
          setImage(user.image);
          setLoading(false);
          setId(user._id);
          setDuesPaid(Date.parse(user.date_due) > Date.now());
          defineMembership(user.membership_type);
        }
        setLoading(false);
        setSuccess(result[0]);
      }
    });
  }, [success]);

  return data ? (
    <div className={classes.root}>
      <Center sx={{ height: "100vh" }}>
        <Container>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <div className={classes.label}>{!success ? rfid_id : null}</div>
          <Center>
            {success ? (
              <Image
                mt="lg"
                mb="lg"
                src={ReturnImgSrc(image)}
                sx={{ maxWidth: "250px", maxHeight: "250px" }}
              />
            ) : null}
          </Center>
          <Title className={classes.title}>
            {success
              ? name + " je prislonio/la karticu!"
              : "Nema korisnika koji je povezan s ovom karticom..."}
          </Title>
          {success ? (
            <Notification
              mt="lg"
              icon={
                duesPaid && remainingStatus && !strictVisit ? (
                  <IconCheck size={18} />
                ) : (
                  <IconX size={18} />
                )
              }
              color={
                duesPaid && remainingStatus && !strictVisit ? "teal" : "red"
              }
              disallowClose
              title="Status članarine"
            >
              {strictVisit
                ? "Vrijeme dolaska je prošlo."
                : !duesPaid
                ? "Članarina je istekla."
                : !remainingStatus
                ? "Prekoračen broj dana."
                : "Članarina vrijedi!"}
            </Notification>
          ) : null}
          <Text size="lg" align="center" className={classes.description}>
            {success
              ? "Ako se pred vama nalazi korisnik sa slike i njegova članarina je aktivna, molimo Vas da kliknete Nastavi."
              : "Kako biste povezali korisnika s ovom karticom, kopirajte navedeni tekst gore i zaljepite ga bez razmaka pod 'RFID' stavku pri uređivanju korisničkih podataka."}
          </Text>
          <Group position="center">
            <Button
              disabled={
                nastavio ||
                !success ||
                !duesPaid ||
                !remainingStatus ||
                strictVisit
              }
              variant="white"
              size="md"
              onClick={handleEnterGym}
            >
              Nastavi
            </Button>
          </Group>
          {nastavio ? (
            <Text
              mt="xs"
              size="lg"
              align="center"
              className={classes.description}
            >
              Korisnik uspješno dodan u prostor teretane, možete zatvoriti ovaj
              prozor.
            </Text>
          ) : null}
        </Container>
      </Center>
    </div>
  ) : null;
}
