import { useState } from 'react'
import { getDefaultHeaders } from "./util/getRequestHeaders";
export const useUserEnteredGym = () => {
    const [error, setError] = useState(null)

    const enterGym = async (_id) => {
        const user = JSON.parse(localStorage.getItem("user"))

        const response = await fetch("/api/gym/entergym", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ "_id": _id })
        })

        const json = await response.json()
        if (!response.ok) {
            setError(json.error)
        } else if (response.ok) {
            return true
        }
    }

    return { enterGym }
}