import { useState, useEffect } from "react";

import {
  createStyles,
  Select,
  TextInput,
  Title,
  Center,
  Text,
  Textarea,
  Button,
} from "@mantine/core";
import { TimeRangeInput } from "@mantine/dates";
import { IconClock, IconInfoSquare, IconBuildingArch } from "@tabler/icons";
import { QRCodeSVG } from "qrcode.react";

import ImageDisplayOrUpload from "../../components/ImageDisplayOrUpload";
import { useInfo } from "../../hooks/Information/useInfo";
import WeeklyTimePicker, { convertStringToDate, Schedule } from "../../components/DateAndTime/WeeklyTimePicker";
import { openModal } from "@mantine/modals";
import ScheduleTable from "../../components/DateAndTime/ScheduleTable";

function ReturnImgSrc(image: any) {
  const base64 = window.btoa(
    new Uint8Array(image.data.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const imgSrc = `data:${image.contentType};base64,${base64}`;
  return imgSrc;
}

const useStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "bottom",
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  form: {
    width: "50vw",
    height: "inherit",
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
  imageHolder: {
    float: "right",
  },
}));

export default function Teretana() {
  const { classes } = useStyles();
  const { setInfo, getInfo } = useInfo();
  const [vrijeme, setRadnoVrijeme] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [image, setImage] = useState("");
  const [image_file, setImageFile] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");

  const [initialSchedule, setInitialSchedule] = useState<Schedule>({
		PON: [],
		UTO: [],
		SRI: [],
		CET: [],
		PET: [],
		SUB: [],
		NED: [],
	});
  
	const [schedule, setSchedule] = useState<Schedule>(initialSchedule);

  const [name_placeholder, setNamePlaceHolder] = useState("Ime teretane");
  const [address_placeholder, setAddressPlaceHolder] = useState(
    "Ul. Vijenac kardinala Alojzija Stepinca 11"
  );
  const [description_placeholder, setDescriptionPlaceHolder] = useState(
    "GymChain Teretana\n     - Radni dani:\n     - Cijena:\nOčekujemo vas! 😁"
  );

  useState(() => {
    getInfo().then((data) => {
      if (data) {
        setImage(data.image);
        setName(data.name);
        setAddress(data.address);
        setDescription(data.description);
        setRadnoVrijeme([new Date(data.open_time), new Date(data.close_time)]);
        const convertedSchedule = Object.keys(data.schedule).reduce((newSchedule, day) => {
          newSchedule[day] = data.schedule[day].map(convertStringToDate);
          return newSchedule;
        }, {} as any);

        setInitialSchedule(convertedSchedule);
      }
    });
  });

  function handleImage(files: any) {
    setImageFile(files[0]);
  }

  const handleInfo = async (event: any) => {
    event.preventDefault();
    if (
      (await setInfo(
        image_file ? image_file : image,
        name,
        address,
        description,
        schedule
      )) == true
    ) {
      console.log("Success");
    }
  };
  const userData = localStorage.getItem("user");
  let employeeId = "";
  if (userData) {
    try {
      const user = JSON.parse(userData);
      employeeId = user.employee._id;
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Center>
          <ImageDisplayOrUpload
            src={image ? ReturnImgSrc(image) : "img/photo-up.png"}
            successCallback={handleImage}
          />
        </Center>
        <Title order={2} mb="sm">
          Podatci o teretani
        </Title>

        <TextInput
          label="Naziv"
          placeholder={name_placeholder}
          icon={<IconInfoSquare size={14} />}
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
        <TextInput
          label="Adresa"
          placeholder={address_placeholder}
          icon={<IconBuildingArch size={14} />}
          onChange={(event) => setAddress(event.target.value)}
          value={address}
        />

        <Textarea
          placeholder={description_placeholder}
          label="Opis"
          autosize
          minRows={2}
          onChange={(event) => setDescription(event.target.value)}
          value={description}
        />
        {/* <TimeRangeInput
          icon={<IconClock size={16} />}
          label="Radno vrijeme"
          value={vrijeme}
          onChange={setRadnoVrijeme}
        /> */}
        <Text size={14} weight={500} color="#212529" mt="xs" mb="xs">Radno vrijeme</Text>
        <WeeklyTimePicker initialSchedule={initialSchedule} onScheduleChange={setSchedule} verticalDisplay/>
        <Button onClick={handleInfo} mt="md">
          Spremi informacije
        </Button>
        <div>
          <Center>
            <QRCodeSVG value={employeeId || ""} />
          </Center>
        </div>
      </div>
    </div>
  );
}
