import { Button, Center, Container, Grid, Paper, SimpleGrid, createStyles, Title, Text, useMantineTheme, Progress, Transition, LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';

import FrequencyChart from '../../components/statistic/FrequencyChart';
import { useInfo } from '../../hooks/Information/useInfo';
import { FetchUsers } from '../../hooks/MembersHook';
import userEvent from '@testing-library/user-event';
import { useToggle } from '@mantine/hooks';
import { ThemeContext } from '@emotion/react';
import { useGetMembers } from '../../hooks/Members/useGetMembers';

const PRIMARY_COL_HEIGHT = 300;

type DisplayData = {
  frequency_array: any,
  total_members: number,
  current_members: number,
  new_members: number,
  paid_members: number
}

let THEME_SPACING_MD: number

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef('icon');
  THEME_SPACING_MD = theme.spacing.md

  return {
    title: {
      letterSpacing: -0.25,
      fontWeight: 500,
    },

    header: {
      fontWeight: 600,
      fontSize: theme.fontSizes.lg
    },

    link: {
      ...theme.fn.focusStyles(),
      height: "10%",
      maxHeight: "50px",
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.md,
      color: theme.white,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      margin: "5px",
      borderRadius: theme.radius.md,
      fontWeight: 400,

      '&:hover': {
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: 'subtle', color: theme.primaryColor }).background!,
          0.2
        ),
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
        color: theme.fn.variant({ variant: 'filled', color: theme.white }).color,
        [`& .${icon}`]: {
          opacity: 0.9,
        },
      },
    },

    userText: {
      fontWeight: 600,
      color: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!, 0
      ),
      lineHeight: 1.2,
      fontSize: 22,
      textDecoration: "italic",
    },

    footer: {
      borderTop: `1px dotted white`,
      paddingTop: theme.spacing.md,
    },
  };
});

export function Statistika() {
  const [refresh, toggleRefresh] = useToggle()
  const [displayData, setData] = useState<DisplayData>()

  const [memberCount, setMemberCount] = useState(0)

  const { getMembersInfo, getMembers } = useGetMembers();
  const { getInfo } = useInfo()
  const { classes, cx } = useStyles();

  const handleRefresh = () => {
    toggleRefresh()
  }

  useEffect(() => {
    let display = { frequency_array: [], total_members: 0, current_members: 0, new_members: 0, paid_members: 0 }
    setData(undefined)

    const loadMembers = async () => {
      // User fetching
      const { minimalList, paginatedList } = await getMembers(-1)
      display.total_members = parseInt(paginatedList.length)

      let now = new Date()
      paginatedList.map((user: any) => {
        if (new Date(user.date_joined).getMonth() == now.getMonth()) {
          display.new_members += 1
        }
        if (Date.now() < Date.parse(user.date_due)) {
          display.paid_members += 1
        }
      })

      getInfo().then(data => {
        if (data) {
          display.frequency_array = data.frequency_data
  
          if (data.activeUsers == undefined) {
            display.current_members = 0
          } else {
            display.current_members = data.activeUsers.length
          }
  
          setData(display)
        }
      })
    }

    loadMembers();
  }, [refresh])

  const SECONDARY_COL_HEIGHT = (PRIMARY_COL_HEIGHT / 2 - THEME_SPACING_MD / 2);

  if (displayData === undefined) {
    return (
      <Container my="md">
        <LoadingOverlay
          loaderProps={{ size: 'sm', color: 'blue', variant: "oval" }}
          overlayOpacity={0.3}
          overlayColor="#c5c5c5"
          visible
        />
      </Container>
    )
  }

  return (
    <Container my="md">
      <Center>
        <Title className={classes.title}>Statistika</Title>
      </Center>
      <Center>
        <Button variant="outline" mt="xs" onClick={() => handleRefresh()}>
          Osvježi
        </Button>
      </Center>
      <Center sx={{ height: "80vh" }}>
        <SimpleGrid cols={1} spacing="md" sx={{ maxHeight: "80vh", width: "100%" }} breakpoints={[{ maxWidth: 'lg', cols: 1 }]}>
          <Paper sx={{ height: PRIMARY_COL_HEIGHT, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} shadow="lg" p="md" withBorder>
            <FrequencyChart input={displayData} />
          </Paper>
          <Grid gutter="md">
            <Grid.Col>
              <Paper sx={{ height: PRIMARY_COL_HEIGHT / 2 }} shadow="lg" p="md" withBorder>
                <Title className={classes.header} mb="xs" align="center">ČLANARINA</Title>
                <Progress
                  size="xl"
                  sections={[
                    { value: ((displayData.paid_members / displayData.total_members) * 100) || 0, color: 'green', label: `${displayData.paid_members} VRIJEDI` },
                    { value: ((displayData.total_members - displayData.paid_members) / displayData.total_members) * 100 || 0, color: 'red', label: `${displayData.total_members - displayData.paid_members} ISTEKLO` },
                  ]
                  }
                />
                <Title className={classes.header} mt="md" mb="xs" align="center">NOVA ČLANSTVA</Title>
                <Text fw={500} align="center">{displayData?.new_members} novih članstava ovaj mjesec.</Text>
              </Paper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Paper sx={{ height: SECONDARY_COL_HEIGHT }} shadow="lg" p="md" withBorder>
                <Title size={16} align="center">UKUPNO ČLANOVA</Title>
                <Text sx={{ margin: "auto", height: "50%" }} size={54} fw={700} align="center" color="red">{displayData?.total_members}</Text>
              </Paper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Paper sx={{ height: SECONDARY_COL_HEIGHT }} shadow="lg" p="md" withBorder>
                <Title size={16} align="center">TRENUTNO ČLANOVA U TERETANI (OTPRILIKE)</Title>
                <Text sx={{ margin: "auto", height: "50%" }} size={54} fw={700} align="center" color="blue">{displayData?.current_members}</Text>
              </Paper>
            </Grid.Col>
          </Grid>
        </SimpleGrid>
      </Center>
    </Container>
  )
}

export default Statistika