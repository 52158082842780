import { showError, showLoading, updateLoading, showSuccess } from '../utils/showPopup'
import { getDefaultHeaders, getFormHeaders } from "./util/getRequestHeaders";

export const useCreateGroup = () => {
    const create = async (color, name, leader, schedule) => {
        const data = new FormData();
        data.append("color", color)
        data.append("name", name)
        data.append("leader", leader)
        data.append("schedule", JSON.stringify(schedule))

        showLoading("Kreiramo grupu...", "useCreateGroup")
        const response = await fetch("/api/groups/create", {
            method: "POST",
            headers: getFormHeaders(),
            body: data
        })
        const json = await response.json()
        if (!response.ok) {
            updateLoading("useCreateGroup", json.error, showError)
        } else if (response.ok) {
            updateLoading("useCreateGroup", `Grupa ${name} uspješno kreiran.`, showSuccess)
            return true
        }
    }
    return { create }
}

export const useDeleteGroup = () => {
    const remove = async (_id, name) => {
        const response = await fetch("/api/groups/delete", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ _id })
        })
        const json = await response.json()
        if (!response.ok) {
            showError(json.error)
        } else if (response.ok) {
            showSuccess(`Grupa ${name} uspješno izbrisana.`)
            return true
        }
    }
    return { remove }
}

export const useGetAllGroups = () => {
    const getAll = async (essentialsOnly) => {
        let response = await fetch("/api/groups/getgroups", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ essentialsOnly })
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        let response_json = await response.json();
        return response_json.groups
    }
    return { getAll }
}

export const useGetGroup = () => {
    const getGroup = async (_id) => {
        let response = await fetch("/api/groups/get", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ _id })
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        let response_json = await response.json();
        return response_json.group
    }
    return { getGroup }
}

export const useUpdateGroup = () => {
    const update = async ({ id, color, name, leader, schedule }) => {
        const data = new FormData();
        data.append("_id", id)
        data.append("color", color)
        data.append("name", name)
        data.append("leader", leader)
        data.append("schedule", JSON.stringify(schedule))

        showLoading("Primjenjujemo promjene na serveru...", "useUpdateGroup")

        const response = await fetch("/api/groups/update", {
            method: "POST",
            headers: getFormHeaders(),
            body: data
        })

        const json = await response.json()

        if (!response.ok) {
            updateLoading("useUpdateGroup", json.error, showError)
        } else if (response.ok) {
            updateLoading("useUpdateGroup", `Podatci za grupu uspješno su promijenjeni.`, showSuccess)
            return true
        }
    }

    return { update }
}