import { getDefaultHeaders } from "./util/getRequestHeaders";

export const useGetLogs = () => {
    const getLogsInfo = async () => {
        let response = await fetch("/api/zapisnik/getlogsinfo", {
            method: "GET",
            headers: getDefaultHeaders(),
            body: JSON.stringify()
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        let response_json = await response.json();
        let { totalPages, totalLogs } = response_json

        return { totalPages, totalLogs }
    }

    const getLogs = async (page) => {
        let response = await fetch("/api/zapisnik/getlogs", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify({ page })
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        let response_json = await response.json();
        let { minimalList, paginatedList } = response_json

        return { minimalList, paginatedList };
    }

    return { getLogsInfo, getLogs }
}