import {Tabs,} from "@mantine/core";
import { IconPlus, IconShieldLock, IconUsers } from "@tabler/icons";
import { useAuthContext } from "../hooks/useAuthContext";
import NewEmployee from "./EmployeesListModal/NewEmployee";
import NewPassword from "./EmployeesListModal/NewPassword";
import EmployeeOverview from "./EmployeesListModal/EmployeeOverview";

interface EmployeesListModalInterface {
  callback: ()=>void;
}

function EmployeesListModal({callback}: EmployeesListModalInterface) {
  const { user } = useAuthContext();

  return (
    <>
      <Tabs defaultValue="employees">
        <Tabs.List grow position="center">
          <Tabs.Tab value="employees" icon={<IconUsers size={14} />}>
            Djelatnici
          </Tabs.Tab>
          <Tabs.Tab value="password" icon={<IconShieldLock size={14} />}>
            Nova lozinka
          </Tabs.Tab>
          {user.employee.name == "Administrator" && (
            <Tabs.Tab value="new" icon={<IconPlus size={14} />}>
              Novi djelatnik
            </Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value="employees" pt="xs" mt="md">
          <EmployeeOverview callback={callback}/>
        </Tabs.Panel>

        <Tabs.Panel value="password" pt="xs" mt="md">
          <NewPassword callback={callback}/>
        </Tabs.Panel>

        {user.employee.name == "Administrator" && (
          <Tabs.Panel value="new" pt="xs" mt="md">
            <NewEmployee callback={callback}/>
          </Tabs.Panel>
        )}
      </Tabs>
    </>
  );
}

export default EmployeesListModal;
