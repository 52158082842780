import { useRef, useState } from 'react'
import { Image, Center } from '@mantine/core'
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useEffect } from 'react';

interface IFormProps {
    src?: string,
    successCallback?: any,
}

export default function ImageDisplayOrUpload({src, successCallback}: IFormProps) {
    const openRef = useRef<() => void>(null);
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const [preview, setPreview] = useState<JSX.Element[] | JSX.Element | undefined>(undefined);

    useEffect(() => {
        console.log("Files updated")
        if (files.length > 0) {
        console.log("Setting preview to not-src")
          setPreview(
            files.map((file, index) => {
              console.log("reached iteration");
              const imageUrl = URL.createObjectURL(file);
              return (
                <Image
                  width="156px"
                  height="156px"
                  fit="cover"
                  imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
                  src={imageUrl}
                  withPlaceholder
                />
              );
            })
          );
        } else if (src) {
            console.log("Setting preview to src")
              setPreview(
                <Image
                  width="156px"
                  height="156px"
                  fit="cover"
                  src={src}
                  withPlaceholder
                />
              );
        }
      }, [files, src]);

    return (
        <>
          <Dropzone
            openRef={openRef}
            maxFiles={1}
            onDrop={(files: any) => {
              setFiles(files);
              successCallback(files);
            }}
            onReject={(files: any) => console.log("rejected files", files)}
            maxSize={3 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}
          >
            <Center>
              {preview}
            </Center>
          </Dropzone>
        </>
    );
}
