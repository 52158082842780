import { useNavigate } from 'react-router-dom'
import { createStyles, Container, Button, Group, Center } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 50,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: theme.colors[theme.primaryColor][3],

        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },
}));

export default function SiteNotFound() {
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <Center sx={{ height: "100vh" }}>
                <Container>
                    <div className={classes.label}>404 - Stranica nije pronađena!</div>
                    <Group position="center">
                        <Button variant="white" size="md" onClick={()=>navigate("/login")}>
                            Idi na početnu stranicu
                        </Button>
                    </Group>
                </Container>
            </Center>
        </div>
    )
}