import { useEffect, useState } from 'react';
import { Title, Stack, Button } from '@mantine/core';

import MemberGroup from "../../components/Groups/MemberGroup";
import CreateGroupModal from '../../components/Groups/CreateGroupModal';
import { useGetAllGroups } from '../../hooks/useMemberGroups';
import ScheduleTable, { ConvertDatabaseObjectToSchedule } from '../../components/DateAndTime/ScheduleTable';
import GroupEditModal from '../../components/Groups/GroupEditModal';


function Grupe() {
    const { getAll } = useGetAllGroups();

    const [openedGroupId, setOpenedGroupId] = useState<string | null>(null);
    const [createGroupModalOpened, setCreateGroupModalOpened] = useState(false);
    const [editGroupModalOpened, setEditGroupModalOpened] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

    const fetchGroups = async () => {
        getAll(false).then((data) => {
            var groups = data.map((group: { _id: string, leader: string, name: string; color: string, schedule: any }) => ({
                _id: group._id,
                name: group.name,
                color: group.color,
                leader: group.leader,
                schedule: ConvertDatabaseObjectToSchedule(group.schedule),
            }));
            setGroups(groups);
        });
    }

    const callback = () => {
        setCreateGroupModalOpened(!createGroupModalOpened);
        fetchGroups();
    }

    const refresh = () => {
        fetchGroups();
        setEditGroupModalOpened(false);
    }

    const handleOpenEditModal = (id: string) => {
        setSelectedGroupId(id);
        setEditGroupModalOpened(true);
    }

    useEffect(() => {
        fetchGroups();
    }, [editGroupModalOpened]);

    return (
        <>
            <Title m="md">Pregled grupa</Title>
            <GroupEditModal groupId={selectedGroupId} isOpened={editGroupModalOpened} callback={refresh}/>
            <CreateGroupModal callback={callback}/>
            <Stack>
                {groups.map((group: { _id: string, leader: string, name: string; color: string, schedule: any }) => (
                    <>
                        <MemberGroup
                            _id={group._id}
                            name={group.name}
                            leader={`Voditelj: ${group.leader ? group.leader : "Nema"}`}
                            color={group.color}
                            memberCount={152}
                            schedule={group.schedule}
                            openedGroupId={openedGroupId}
                            setOpenedGroupId={setOpenedGroupId}
                            openEditModal={handleOpenEditModal}
                            refresh={refresh}
                        />
                    </>
                ))}
            </Stack>
        </>
    );
}

export default Grupe;