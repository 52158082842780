import { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Navbar,
  SegmentedControl,
  Text,
  createStyles,
  AppShell,
  MantineGradient,
  Image,
  Group,
  Stack,
  Badge,
} from "@mantine/core";
import {
  IconLicense,
  IconUsers,
  IconJumpRope,
  IconHome,
  IconChartBar,
} from "@tabler/icons";

import { useLogout } from "../hooks/useLogout";
import SideMenu from "./SideMenu";
import { useAuthContext } from "../hooks/useAuthContext";
import ActiveUser from "../components/Sidebar/ActiveUser";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");

  return {
    navbar: {
      backgroundImage: theme.fn.gradient({
        from: "#141414",
        to: "#0a0a0a",
        deg: 25,
      }),
      maxWidth: "20%",
    },

    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      height: "10vh",
    },

    title: {
      textTransform: "uppercase",
      letterSpacing: -0.25,
    },

    text: {
      height: "10%",
      maxHeight: "50px",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.md,
      color: theme.white,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      margin: "5px",
      opacity: "40%",
    },

    link: {
      ...theme.fn.focusStyles(),
      height: "10%",
      maxHeight: "50px",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.md,
      color: theme.white,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      margin: "5px",
      borderRadius: theme.radius.md,
      fontWeight: 400,

      "&:hover": {
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: "subtle", color: theme.primaryColor })
            .background!,
          0.2
        ),
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "filled",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "filled", color: theme.white })
          .color,
        [`& .${icon}`]: {
          opacity: 0.9,
        },
      },
    },

    userText: {
      fontWeight: 600,
      color: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0
      ),
      lineHeight: 1.2,
      fontSize: 22,
      textDecoration: "italic",
    },

    footer: {
      paddingTop: theme.spacing.xs,
    },
  };
});

const tabs = {
  general: [
    { link: "", label: "Teretana", icon: IconHome },
    { link: "", label: "Članovi", icon: IconUsers },
    { link: "", label: "Grupe", icon: IconJumpRope },
    { link: "", label: "Statistika", icon: IconChartBar },
    { link: "", label: "Zapisnik", icon: IconLicense },
  ],
};

interface IDashboard {
  selection?: string;
}

// default selekcija je = "teretana", ali prioritet ima savedtab
export default function Dashboard({ selection = "teretana" }: IDashboard) {
  const { user } = useAuthContext();
  const saved_tab: string | null = localStorage.getItem("saved_tab");
  const { classes, cx } = useStyles();
  const [active, setActive] = useState(saved_tab || selection);
  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  const links = tabs["general"].map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label.toLowerCase() === active,
      })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label.toLowerCase());
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          height="100vh"
          width={{ sm: 300 }}
          p="md"
          className={classes.navbar}
        >
          <Navbar.Section grow>
            <Stack >
            <Group className={classes.header} position="center">
              <Image sx={{ align: "center", maxWidth: "256px" }} src="img/logo_long.png"/>
            </Group>
            </Stack>
            <Text className={classes.text}>IZBORNIK</Text>
            {links}
          </Navbar.Section>
          <ActiveUser onLogout={handleLogout}/>

        <Navbar.Section>
          <Badge color="gray" size="xs" variant="outline">V.1.1.2</Badge>
        </Navbar.Section>
        </Navbar>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <SideMenu
        data={active.toLowerCase()}
        onClick={localStorage.setItem("saved_tab", active.toLowerCase())}
      ></SideMenu>
    </AppShell>
  );
}
