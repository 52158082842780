import { TextInput } from "@mantine/core";
import { useState } from "react";

function NonDiacriticTextInput(props: any) {
  const [value, setValue] = useState(props.value || "");

  // Function to remove diacritics
  const handleChange = (event: any) => {
    const inputValue = event.target.value;
  
    let normalizedValue = inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    normalizedValue = normalizedValue.replace(/Đ/g, "D").replace(/đ/g, "d");
  
    setValue(normalizedValue)
  
    // Trigger the parent component's onChange with sanitized input
    if (props.onChange) {
      props.onChange({
        ...event,
        target: {
          ...event.target,
          value: normalizedValue,
        },
      });
    }
  };
  
  // Iz nekog razloga, react izostavi zadnje slovo prilikom filtracije, zbog toga sam dodao onMouseLeave da JOŠ JEDNOM PROVJERI jel sve očišćeno.
  // U svakom slučaju, prije spremanja na server svakako još jednom filtriraj na backendu jer ovo je u stanju da sve sjebe.

  return (
    <TextInput 
      {...props} 
      value={value}  // Display the sanitized value from state
      onChange={handleChange}  // Handle input changes
      onMouseLeave={handleChange}
    />
  );
}

export default NonDiacriticTextInput;
